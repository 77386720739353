<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="4">
        <v-layout justify-center class="ma-2 pa-2">
          <v-card class="pa-2">
            <v-icon class="ma-2">mdi-school</v-icon> S.A.C Dip<br />
            <v-icon class="ma-2">mdi-email</v-icon>
            thefootnurse@hotmail.com<br />
            <v-icon class="ma-2">mdi-cellphone</v-icon> 07828831577<br />
            <v-icon class="ma-2">mdi-home-variant</v-icon> Flackwell, High
            Wycombe, HP10 9EZ
          </v-card>
        </v-layout>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="4" align="center">
        <v-card>
          <v-img
            :src="require('../assets/images/foot_nurse_android_v2.png')"
            height="100%"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title class="justify-center">About Me</v-card-title>
          <p class="pl-2">
            My name is Clare and I am a mobile Foot Health Practitioner visiting
            you in the comfort of your own home.
          </p>
          <p class="pl-2">
            I qualified in Chiropody in 2019 and I am fully insured and able to
            offer treatments and advice on any foot problem that is non-
            musculoskeletal.
          </p>
          <p class="pl-2">
            This includes verrucas, corns, callus, fungal nails, ingrown
            toenails and nail trimming service.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style scoped></style>
