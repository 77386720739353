<template>
  <section id="about" class="overflow-hidden">
    <v-container class="corpFour bg-color">
      <v-row no-gutters>
        <v-col class="pa-5" cols="12" md="6">
          <v-img
            class="grow"
            contain
            max-height="200"
            width="100%"
            position="top left"
            :src="require('../assets/images/bubbles1.png')"
          />

          <h1 class="fnurse--text pt-4 pb-4">Pricing</h1>

          <base-text class="mb-5 pt-4 pb-4">
            Most treatments take approximately 1 hour but<br />
            callus removal can take longer depending on how much<br />
            build up there is. Please see below for my price list.
          </base-text>

          <h2 class="fnurse--text pt-4 pb-4">Pricing by Treatment</h2>

          <v-alert outlined class="white">
            <v-row
              v-for="(skill, i) in skills"
              :key="i"
              class="ma-4 pa-4 white"
            >
              <v-col class="text-uppercase" cols="6" v-text="skill.name" />

              <v-col class="text-right" cols="6" v-text="`${skill.value}`" />
            </v-row>
          </v-alert>
        </v-col>

        <v-col md="6">
          <v-img :src="require('../assets/images/feet.png')" height="100%" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PricingAmounts",
  data: () => ({
    skills: [
      {
        name: "Corn Removal",
        value: "£35.00",
      },
      {
        name: "Verruca Management",
        value: "£35.00 (Variable)",
      },
      {
        name: "Callus Removal",
        value: "£35.00 to £45.00",
      },
      {
        name: "Fungal Nails",
        value: "£variable",
      },
      {
        name: "Ingrown Toenails",
        value: "£35.00",
      },
      {
        name: "Toe nail trimming",
        value: "£35.00",
      },
    ],
  }),
};
</script>

<style scoped>
.bg-color {
  background-color: #12d2e3;
}
</style>
