<template>
  <v-app id="inspire">
    <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

    <v-navigation-drawer app class="pt-4" v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <router-link to="/" tag="span" style="cursor: pointer"
              >The Foot Nurse</router-link
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon large>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer app class="pt-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> The Foot Nurse </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon large>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    drawer: false,
    tab: null,
    items: [
      { title: "Home", icon: "mdi-home", route: "/" },
      { title: "Contact", icon: "mdi-message-text", route: "/contact" },
      { title: "Pricing", icon: "mdi-view-list", route: "/pricing" },
    ],
    right: null,
  }),
};
</script>
