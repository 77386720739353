<template>
  <section id="get-in-touch">
    <v-container class="pa-2 corpFour bg-color">
      <v-row class="corpFour" no-gutters align="center" justify="center">
        <v-col class="pa-5" cols="12" md="6">
          <v-img
            class="grow"
            contain
            max-height="200"
            width="100%"
            position="top left"
            :src="require('@/assets/images/bubbles1.png')"
          />

          <base-heading class="mb-5"> Get In Touch </base-heading>

          <v-sheet color="transparent" max-width="600">
            <form @submit.prevent="sendEmail">
              <v-text-field
                color="lightPurple"
                label="Name"
                name="name"
                v-model="name"
                solo
                flat
              />

              <v-text-field
                color="info"
                label="Email"
                name="email"
                v-model="email"
                solo
                flat
              />

              <v-text-field
                color="info"
                label="Subject"
                name="subject"
                v-model="subject"
                solo
                flat
              />

              <v-textarea
                color="info"
                label="Message"
                name="message"
                v-model="message"
                solo
                flat
              />

              <v-btn
                color="secondary"
                rounded
                v-bind="$attrs"
                v-on="$listeners"
              >
                <input type="submit" value="Send" />
              </v-btn>
            </form>
          </v-sheet>
        </v-col>
        <v-col md="6">
          <v-img :src="require('@/assets/images/welcome.png')" height="100%" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "ContactInfo",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      try {
        emailjs.sendForm(
          "service_epah1wq",
          "template_n0tccdc",
          e.target,
          "user_Hu4TkJGU8dSFgyfR6pLFv",
          {
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.subject = "";
      this.message = "";
    },
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #12d2e3;
}
</style>
